const Error404 = () => {
  return (
    <section className="mt-lg-5">
      <h1 className="text-center">Error 404</h1>
      <p className="text-center">
        The page you are looking for can not be found or does not exist
      </p>
      <p className="text-center">
        Go back to <a href="/">Home</a>
      </p>
    </section>
  );
};

export default Error404;
